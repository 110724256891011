
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React, { useEffect } from "react";
import "@/styles/globals.css";
import "/src/styles/prism-atom-dark.css";
import type { AppProps } from "next/app";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import NextNProgress from "nextjs-progressbar";
import Script from "next/script";
import { useRouter } from "next/router";
const theme = extendTheme({
    styles: {
        global: {
            body: {
                minHeight: "100vh",
                backgroundColor: "gray.50"
            },
            ".onBanner + .show": {
                display: "block"
            },
            ".share-wrapper button": {
                filter: "grayscale(1)"
            },
            ".share-wrapper button:hover": {
                filter: "grayscale(0)"
            }
        }
    }
});
const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
const GADS_ID = process.env.NEXT_PUBLIC_GADS_ID;
export const gtmVirtualPageView = (rest: any) => {
    // @ts-ignore
    window.dataLayer?.push({
        event: "VirtualPageView",
        ...rest
    });
};
function App({ Component, pageProps }: AppProps) {
    const router = useRouter();
    useEffect(() => {
        const mainDataLayer = {
            pageTypeName: pageProps.page || null,
            url: router.pathname
        };
        gtmVirtualPageView(mainDataLayer);
    }, [pageProps]);
    return (<>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `}
      </Script>
      <Script id="Adsense-id" data-ad-client={GADS_ID} async strategy="afterInteractive" src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" crossOrigin="anonymous" onError={(e) => { console.error('Script failed to load', e); }}/>
      <ChakraProvider theme={theme}>
        <NextNProgress height={3}/>
        <Component {...pageProps}/>
      </ChakraProvider>
    </>);
}

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  